<template>
  <nav class="border-b" aria-label="Tabs">
    <div v-for="route in routes" :key="route.name" class="inline-block">
      <router-link
        v-if="profile.role === 'admin' || route.role === 'user'"
        :to="route.path"
        class="block px-4 py-2 text-lg font-medium"
        :class="[route.path === $route.path ? 'border-b-2 border-indigo-600 text-indigo-600' : 'text-gray-600 hover:text-gray-800', 'rounded-t-md']"
        :aria-current="route.path === $route.path ? 'page' : undefined"
      >
        {{ route.name }}
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts">
import { useProfileStore } from "@/stores"
import { onMounted, ref } from "vue"
import { Profile } from "@/types/hrn"

export default {
  setup() {
    const profileStore = useProfileStore()
    const profile = ref({} as Profile)
    onMounted(async () => {
      profile.value = await profileStore.getProfile()
    })
    const routes = [
      {
        name: "My Profile",
        path: "/profile",
        role: "user",
      },
      {
        name: "Qualifiers",
        path: "/admin/qualifiers",
        role: "admin",
      },
      {
        name: "Categories",
        path: "/admin/categories",
        role: "admin",
      },
      {
        name: "Services",
        path: "/admin/services",
        role: "admin",
      },
      {
        name: "Service Organizations",
        path: "/admin/service-organizations",
        role: "admin",
      },
      {
        name: "Users",
        path: "/admin/users",
        role: "admin",
      },
      {
        name: "Pages",
        path: "/admin/pages",
        role: "admin",
      },
      {
        name: "Attachments",
        path: "/admin/attachments",
        role: "admin",
      },
      {
        name: "Blog",
        path: "/admin/blog-posts",
        role: "admin",
      },
      {
        name: "Settings",
        path: "/admin/settings",
        role: "admin",
      },
    ]
    return {
      routes,
      profile,
    }
  },
}
</script>
```
