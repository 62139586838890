<template>
  <div class="flex gap-3">
    <span class="cursor-pointer text-gray-300" v-if="!authStore.isAuthenticated" @click="router.push('/sign-in')">Sign In</span>
    <Menu as="div" class="menu-wrapper" v-if="authStore.isAuthenticated">
      <MenuButton class="menu-button flex gap-2 text-sm text-white">
        <span class="sr-only">Open user menu</span>
        <span>{{ authStore.username }}</span>

        <ChevronDownIcon class="h-4 w-4" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition-base"
        enter-from-class="enter-from"
        enter-to-class="enter-to"
        leave-active-class="leave-active"
        leave-from-class="leave-from"
        leave-to-class="leave-to"
      >
        <MenuItems class="menu-items">
          <MenuItem v-for="item in authenticatedNav" :key="item.name" v-slot="{ active }" class="cursor-pointer">
            <a @click="navigate(item.href)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }} </a>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
    <LightDarkToggle />
  </div>
</template>
<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import { ChevronDownIcon } from "@heroicons/vue/24/outline"
import { useRouter } from "vue-router"
import { useAuthStore } from "@/stores/AuthStore.ts"

import LightDarkToggle from "@/views/components/LightDarkToggle.vue"
import { useProfileStore } from "@/stores"

export default {
  name: "UserMenu",
  components: { LightDarkToggle, Menu, MenuButton, MenuItems, MenuItem, ChevronDownIcon },
  setup() {
    const authStore = useAuthStore()
    const profileStore = useProfileStore()
    const router = useRouter()

    const authenticatedNav = []
    authenticatedNav.push({ name: "Profile", href: "profile", role: "user" })
    if (profileStore.role === "admin") {
      authenticatedNav.push({ name: "Admin", href: "admin", role: "admin" })
    }
    authenticatedNav.push({ name: "Sign Out", href: "/sign-out", role: "user" })

    return {
      authenticatedNav,
      router,
      authStore,
      profileStore,
    }
  },
  methods: {
    navigate(href: string) {
      this.router.push({ name: href })
    },
  },
}
</script>
<style scoped>
.menu-wrapper {
  @apply relative ml-3;
}

.menu-button {
}

.menu-button-img {
  @apply h-8 w-8 rounded-full;
}

.transition-base {
  @apply transition duration-200 ease-out;
}

.enter-from {
  @apply scale-95 transform opacity-0;
}

.enter-to {
  @apply scale-100 transform opacity-100;
}

.leave-active {
  @apply transition duration-75 ease-in;
}

.leave-from {
  @apply scale-100 transform opacity-100;
}

.leave-to {
  @apply scale-95 transform opacity-0;
}

.menu-items {
  @apply absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.menu-item-active {
  @apply bg-gray-100;
}

.menu-item {
  @apply block px-4 py-2 text-sm text-gray-700;
}
</style>
