<template>
  <footer aria-labelledby="footer-heading" class="p-10">
    <div class="mx-auto w-full max-w-7xl">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto">
        <p class="text-xs leading-5 text-gray-400">&copy; 2023 Home Repair Network. All rights reserved.</p>
        <p class="text-xs leading-5 text-gray-400">Made with &#9829; at <a href="https://bishopand.com">Bishop & Co.</a></p>
      </div>
    </div>
  </footer>
</template>
<style scoped>
footer {
  background-color: #286359;
}
</style>
<script setup lang="ts"></script>
