<template>
  <div class="app-root animated-gradient flex h-screen items-center justify-center bg-gray-300">
    <div class="flex items-center justify-center gap-5">
      <img class="mb-10 block h-[12em] w-auto rounded-2xl bg-gray-700 p-10" :src="logo" alt="Home Repair Network" />
      <div>
        <h1>St. Louis Home Repair Network is preparing for launch 🚀</h1>
        <h2>Please check back soon!</h2>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import logo from "@/assets/logo.svg"
export default {
  data() {
    return {
      logo,
    }
  },
}
</script>
