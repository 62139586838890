import { defineStore } from "pinia"
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js"
import { useLocalStorage } from "@vueuse/core"
import { AuthResult, UserAttributes } from "@/types/hrn"

const poolData = {
  UserPoolId: import.meta.env.VITE_APP_USER_POOL_ID as string,
  ClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID as string,
}

const userPool = new CognitoUserPool(poolData)

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    username: useLocalStorage("username", ""),
    cognitoUser: useLocalStorage("cognitoUser", null as CognitoUser | null),
    authenticated: useLocalStorage("authenticated", false),
    jwtToken: useLocalStorage("jwtToken", null as string | null),
    session: null as CognitoUserSession | null,
  }),
  actions: {
    setCognitoUser(session: CognitoUser) {
      console.log("Auth Store: setCognitoUser", session)
      this.cognitoUser = session // Assign cognitoUser to the store state
      this.authenticated = true
      console.log("Auth Store: setCognitoUser: authenticated ✅", this.authenticated)
    },
    setUsername(username: string) {
      console.log("Auth Store: setUsername", username)
      this.username = username
    },
    setJwtToken(token: string) {
      console.log("Auth Store: setJwtToken", token)
      this.jwtToken = token // Assign JWT token to the store state
    },
    signOut() {
      this.cognitoUser = null
      this.authenticated = false
      this.jwtToken = ""
      localStorage.removeItem("cognitoUser")
    },
    signIn(username: string, password: string): Promise<AuthResult> {
      return new Promise((resolve, reject) => {
        const authenticationData = {
          Username: username,
          Password: password,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)

        const userData = {
          Username: username,
          Pool: userPool,
        }

        const cognitoUser = new CognitoUser(userData)
        this.setUsername(userData.Username)

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (session) => {
            this.setCognitoUser(cognitoUser) // Set the CognitoUser in the store
            this.setJwtToken(session.getIdToken().getJwtToken()) // Set the JWT token in store state
            resolve({ newPasswordRequired: false })
          },
          onFailure: (err) => {
            reject(err)
          },
          newPasswordRequired: (userAttributes) => {
            resolve({ newPasswordRequired: true, userAttributes })
          },
        })
      })
    },
    async privateEndpoint() {
      const headers = {
        Authorization: `Bearer ${this.jwtToken}`,
      }
      const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/private`, {
        headers: headers,
      })
      console.log(response)
    },
    setNewPassword(newPassword: string, userAttributes: UserAttributes): Promise<void> {
      return new Promise((resolve, reject) => {
        const authenticationData = {
          Username: userAttributes.username,
          Password: userAttributes.oldPassword,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)
        const userData = {
          Username: userAttributes.username,
          Pool: userPool,
        }

        const cognitoUser = new CognitoUser(userData)

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function () {},
          onFailure: function (err) {
            reject(err)
          },
          newPasswordRequired: function () {
            cognitoUser.completeNewPasswordChallenge(
              newPassword,
              {},
              {
                onSuccess: function () {
                  resolve()
                },
                onFailure: function (err) {
                  reject(err)
                },
              }
            )
          },
        })
      })
    },
    sendCode(username: string) {
      return new Promise((resolve, reject) => {
        const userData = {
          Username: username,
          Pool: userPool,
        }
        const cognitoUser = new CognitoUser(userData)

        cognitoUser.resendConfirmationCode((err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        })
      })
    },
    async whoami(): Promise<void> {
      const cognitoUser = this.cognitoUser

      if (cognitoUser) {
        try {
          const headers = {
            Authorization: `Bearer ${this.jwtToken}`,
          }

          const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/private`, {
            headers: headers,
          })

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }

          const data = await response.json()
          console.log(data)
        } catch (error) {
          console.log(error)
          throw error
        }
      } else {
        throw new Error("No authenticated user found.")
      }
    },
    getUsername(): string {
      return this.username
    },
    async checkTokenValidity() {
      console.log("Auth Store: checkTokenValidity")
      if (!this.cognitoUser || !this.jwtToken) {
        this.authenticated = false
        throw new Error("No authenticated user or token found.")
      }

      const currentTime = new Date().getTime() / 1000
      const decodedToken = JSON.parse(atob(this.jwtToken.split(".")[1]))
      const expirationTime = decodedToken.exp

      if (currentTime > expirationTime) {
        await this.refreshToken()
      }
      console.log("Auth Store: checkTokenValidity: Token is valid. ✅")
    },
    async refreshToken() {
      if (!this.cognitoUser) {
        this.signOut()
        throw new Error("No Cognito user found. Please sign in again.")
      }

      try {
        const session = await this.fetchSession()
        if (!session.isValid()) {
          this.signOut()
          throw new Error("Session is invalid. Please sign in again.")
        }

        await this.refreshCognitoSession(session)
      } catch (error) {
        this.signOut()
        throw error
      }
    },
    fetchSession(): Promise<CognitoUserSession> {
      return new Promise((resolve, reject) => {
        this.cognitoUser!.getSession((err: Error | null, session: CognitoUserSession | null) => {
          if (err || !session) {
            reject(new Error("Error getting session."))
          } else {
            resolve(session)
          }
        })
      })
    },
    refreshCognitoSession(session: CognitoUserSession): Promise<void> {
      return new Promise((resolve, reject) => {
        const refreshToken = session.getRefreshToken()
        this.cognitoUser!.refreshSession(refreshToken, (err, newSession) => {
          if (err || !newSession) {
            reject(new Error("Unable to refresh token. Please sign in again."))
          } else {
            this.setJwtToken(newSession.getIdToken().getJwtToken())
            resolve()
          }
        })
      })
    },
  },
  getters: {
    currentSession(): CognitoUserSession | null {
      return this.session as CognitoUserSession | null
    },
    isAuthenticated(): boolean {
      return this.authenticated
    },
    getJwtToken(): string {
      return this.jwtToken as string
    },
    isAdmin(): boolean {
      return this.username === "admin"
    },
  },
})
