import pluralize from "pluralize"

declare global {
  interface String {
    apiEndpoint(): string
    pluralize(): string
    singularize(): string
    prettyDate(): string
    prettyDateTime(): string
  }
  interface Number {
    prettyDate(): string
    prettyDateTime(): string
  }
}

String.prototype.apiEndpoint = function () {
  return this.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase()
}

String.prototype.pluralize = function () {
  return `${pluralize.plural(this as string)}Controller`
}

String.prototype.singularize = function () {
  return `${pluralize.singular(this as string)}`
}

String.prototype.prettyDate = function () {
  const date = new Date(this as string)
  if (isNaN(date.getTime())) {
    return "Invalid Date"
  }

  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" }
  return date.toLocaleDateString("en-US", options)
}

String.prototype.prettyDateTime = function () {
  const date = new Date(this as string)
  if (isNaN(date.getTime())) {
    return "Invalid Date"
  }

  const dateOptions = { year: "numeric", month: "long", day: "numeric" }
  const timeOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" }

  const dateString = (date as any).toLocaleDateString("en-US", dateOptions)
  const timeString = (date as any).toLocaleTimeString("en-US", timeOptions)

  return `${dateString}, ${timeString}`
}

Number.prototype.prettyDate = function () {
  return new Date(Number(this)).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

Number.prototype.prettyDateTime = function () {
  return new Date(Number(this)).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}
