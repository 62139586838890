import { defineStore } from "pinia"
import { Service } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"
import { useCategoriesStore } from "@/stores"

export const useServicesStore = defineStore({
  id: "services",
  state: () => ({
    items: [] as Service[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "services")
    },
    async addNewItem(item: Service) {
      await createItem(this, "services", item)
    },
    async removeItem(item: Service) {
      await destroyItem(this, "services", item)
    },
    async changeItem(item: Service) {
      await updateItem(this, "services", item)
    },
    getItems(): Service[] {
      const categoriesStore = useCategoriesStore()
      const categories = categoriesStore.getItems()
      return this.items.map((obj) => {
        const category = categories.find((item) => item.category === obj.category)
        if (!category) return { ...obj, categoryName: "Missing Category" }
        return { ...obj, categoryName: category.name }
      })
    },
    getItemById(id: string): Service | undefined {
      return this.items.find((item) => item.id === id)
    },
  },
})
