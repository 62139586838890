import { createRouter, createWebHistory } from "vue-router"
import { useAuthStore } from "@/stores/AuthStore.ts"
import { useToast } from "vue-toastification"

const siteName = "Home Repair Network"
const t = (pageTitle: string | unknown) => (pageTitle ? `${pageTitle} | ${siteName}` : siteName)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: { title: "Home Page", access: "public", layout: "home" },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/sessions/SignInView.vue"),
    meta: { title: "Sign In", access: "public" },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/sessions/SignUpView.vue"),
    meta: { title: "Sign Up", access: "public" },
  },
  {
    path: "/sign-out",
    name: "sign-out",
    component: () => import("@/views/SignOutView.vue"),
    meta: { title: "Sign Out", access: "public" },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/sessions/ForgotPasswordView.vue"),
    meta: { title: "Forgot Password", access: "public" },
  },
  {
    path: "/sign-up/confirmation",
    name: "sign-up-confirmation",
    component: () => import("@/views/sessions/SignUpConfirmationView.vue"),
    meta: { title: "Sign Up Confirmation", access: "public" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileView.vue"),
    meta: { title: "Profile", access: "restricted" },
  },
  {
    path: "/profile/edit",
    component: () => import("@/views/ProfileView.vue"),
    meta: { title: "Edit Profile", access: "restricted" },
  },
  {
    path: "/profile/service-organizations/new",
    component: () => import("@/views/profile/NewServiceOrganization.vue"),
    meta: { title: "New Service Organization", access: "restricted" },
  },
  {
    path: "/profile/service-organizations/:id",
    component: () => import("@/views/admin/service-organizations/ShowView.vue"),
    meta: { title: "Show Profile", access: "restricted" },
  },
  {
    path: "/service-wizard",
    name: "service-wizard-step-1",
    component: () => import("@/views/service-wizard/Step1View.vue"),
    meta: { title: "Service Wizard Step 1", access: "public" },
  },
  {
    path: "/service-wizard/step-2",
    name: "service-wizard-step-2",
    component: () => import("@/views/service-wizard/Step2View.vue"),
    meta: { title: "Service Wizard Step 2", access: "public" },
  },
  {
    path: "/service-wizard/step-3",
    name: "service-wizard-step-3",
    component: () => import("@/views/service-wizard/Step3View.vue"),
    meta: { title: "Service Wizard Step 3", access: "public" },
  },
  {
    path: "/service-wizard/step-4",
    name: "service-wizard-step-4",
    component: () => import("@/views/service-wizard/Step4View.vue"),
    meta: { title: "Service Wizard Step 4", access: "public" },
  },
  {
    path: "/service-wizard/step-5",
    name: "service-wizard-step-5",
    component: () => import("@/views/service-wizard/Step5View.vue"),
    meta: { title: "Service Wizard Step 5", access: "public" },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/IndexView.vue"),
    meta: { title: "Admin", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/categories",
    name: "admin-categories",
    component: () => import("@/views/admin/categories/IndexView.vue"),
    meta: { title: "Admin Categories", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/settings",
    name: "admin-settings",
    component: () => import("@/views/admin/settings/IndexView.vue"),
    meta: { title: "Admin Settings", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/qualifiers",
    name: "admin-qualifiers",
    component: () => import("@/views/admin/qualifiers/IndexView.vue"),
    meta: { title: "Admin Qualifiers", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/services",
    name: "admin-services",
    component: () => import("@/views/admin/services/IndexView.vue"),
    meta: { title: "Admin Services", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/service-organizations",
    name: "admin-service-organizations",
    component: () => import("@/views/admin/service-organizations/IndexView.vue"),
    meta: { title: "Admin Service Organizations", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/service-organizations/:id",
    name: "admin-service-organizations-show",
    component: () => import("@/views/admin/service-organizations/ShowView.vue"),
    meta: { title: "Admin Service Organizations", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/users",
    name: "admin-users",
    component: () => import("@/views/admin/users/IndexView.vue"),
    meta: { title: "Admin Users", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/pages",
    name: "admin-pages",
    component: () => import("@/views/admin/pages/IndexView.vue"),
    meta: { title: "Admin Pages", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/pages/:id",
    name: "admin-pages-show",
    component: () => import("@/views/admin/pages/ShowView.vue"),
    meta: { title: "Admin Pages", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/blog-posts",
    name: "admin-blog-posts",
    component: () => import("@/views/admin/blog_posts/IndexView.vue"),
    meta: { title: "Admin Blog Posts", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/blog-posts/:id",
    name: "admin-blog-posts-show",
    component: () => import("@/views/admin/blog_posts/ShowView.vue"),
    meta: { title: "Admin Blog Posts", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/users/:id",
    name: "admin-users-show",
    component: () => import("@/views/admin/users/ShowView.vue"),
    meta: { title: "Admin Users", access: "restricted", layout: "admin" },
  },
  {
    path: "/admin/attachments",
    name: "admin-attachments",
    component: () => import("@/views/admin/attachments/IndexView.vue"),
    meta: { title: "Admin Attachments", access: "restricted", layout: "admin" },
  },
  {
    path: "/invite-member",
    name: "invite-member",
    component: () => import("@/views/InviteMember.vue"),
    meta: { title: "Page", access: "public" },
  },
  {
    path: "/:url(.*)*",
    name: "static-page",
    component: () => import("@/views/PageView.vue"),
    meta: { title: "Page", access: "public" },
  },
]

const router = createRouter({
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, _, next) => {
  document.title = t(to.meta.title) || t("")
  const authStore = useAuthStore()
  const toast = useToast()

  if (to.meta.access === "restricted") {
    if (!authStore.isAuthenticated) {
      toast.error("You must be logged in")
      next({ name: "sign-in" })
      return
    }
  }
  next()
})

export default router
