import { defineStore } from "pinia"
import { Page } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"

export const usePagesStore = defineStore({
  id: "page",
  state: () => ({
    items: [] as Page[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "pages")
    },
    async addNewItem(item: Page) {
      await createItem(this, "pages", item)
    },
    async removeItem(item: Page) {
      await destroyItem(this, "pages", item)
    },
    async changeItem(item: Page) {
      console.log("changeItem", item)
      await updateItem(this, "pages", item)
    },
    getItems(): Page[] {
      return this.items
    },
    getItemById(id: string): Page | undefined {
      return this.items.find((item) => item.page === id)
    },
    getItemByUrl(url: string): Page | undefined {
      return this.items.find((item) => item.url === url)
    },
  },
})
