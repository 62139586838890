import { defineStore } from "pinia"
import { Qualifier } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"

export const useQualifiersStore = defineStore({
  id: "qualifiers",
  state: () => ({
    items: [] as Qualifier[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "qualifiers")
    },
    async addNewItem(item: Qualifier) {
      await createItem(this, "qualifiers", item)
    },
    async removeItem(item: Qualifier) {
      await destroyItem(this, "qualifiers", item)
    },
    async changeItem(item: Qualifier) {
      await updateItem(this, "qualifiers", item)
    },
    getItems(): Qualifier[] {
      return this.items
    },
    getItemById(qualifier: string): Qualifier | undefined {
      return this.items.find((item) => item.qualifier === qualifier)
    },
  },
})
