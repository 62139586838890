import { defineStore } from "pinia"
import { Setting } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"

export const useSettingsStore = defineStore({
  id: "setting",
  state: () => ({
    items: [] as Setting[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "settings")
    },
    async addNewItem(item: Setting) {
      await createItem(this, "settings", item)
    },
    async removeItem(item: Setting) {
      await destroyItem(this, "settings", item)
    },
    async changeItem(item: Setting) {
      await updateItem(this, "settings", item)
    },
    getItems(): Setting[] {
      return this.items
    },
  },
})
