<template>
  <transition name="fade">
    <div v-if="visible" class="absolute left-0 top-0 z-[10] flex h-full w-full items-center justify-center bg-white/90 transition-opacity dark:bg-gray-900/90">
      <Spinner class="text-primary-500" />
      <p v-if="message">{{ message }}</p>
    </div>
  </transition>
</template>

<script lang="ts">
import Spinner from "@/views/effects/Spinner.vue"

export default {
  name: "LoadingOverlay",
  components: { Spinner },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
    },
  },
}
</script>

<style scoped>
/* Transition classes for fade effect */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
