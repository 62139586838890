<template>
  <div class="app-root">
    <div v-if="!isLoading">
      <BreakpointIndicator />
      <div class="flex min-h-screen flex-col">
        <Disclosure as="nav" class="z-[1000] bg-[#286359]" v-slot="{ open }">
          <div class="mx-auto max-w-7xl border-b-2 border-[#286359] px-6">
            <div class="flex h-24 justify-between">
              <MainMenu />
              <div class="ml-6 hidden sm:flex sm:items-center">
                <UserMenu class="z-[1000]" />
              </div>
              <div class="-mr-2 flex items-center sm:hidden">
                <span class="text-sm font-bold text-gray-500">Menu</span>
                <DisclosureButton
                  class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 pb-3 pt-2">
              <DisclosureButton
                v-for="item in navigation"
                :key="item.name"
                as="a"
                :href="item.href"
                :class="[
                  item.current ? 'border-indigo-500 bg-indigo-50 text-indigo-700' : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                  'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}
              </DisclosureButton>
            </div>
            <div class="border-t border-gray-200 pb-3 pt-4">
              <div class="flex items-center px-4">
                <div class="ml-3"></div>
                <button
                  type="button"
                  class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">View notifications</span>
                  <BellIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
        <main class="flex-grow">
          <div class="mx-auto min-h-[50vh] max-w-7xl p-5">
            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
        </main>
        <FooterView />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import "@/style.css"
import BreakpointIndicator from "@/views/partials/BreakpointIndicator.vue"
import FooterView from "@/views/partials/FooterView.vue"

import { useAuthStore } from "@/stores/AuthStore.ts"
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue"
import MainMenu from "@/views/partials/MainMenu.vue"
import NotificationsMenu from "@/NotificationsMenu.vue"
import UserMenu from "@/views/partials/UserMenu.vue"
import { BellIcon, Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline"
import { string } from "postcss-selector-parser"
import logo from "@/assets/logo.svg"
import LoadingOverlay from "@/views/components/LoadingOverlay.vue"
import { useSettingsStore } from "@/stores/SettingsStore.ts"
import { onMounted, ref } from "vue"
import { Setting } from "@/types/hrn"

export default {
  methods: { string },
  components: {
    LoadingOverlay,
    DisclosureButton,
    UserMenu,
    NotificationsMenu,
    MainMenu,
    Disclosure,
    DisclosurePanel,
    BreakpointIndicator,
    FooterView,
    BellIcon,
    Bars3Icon,
    XMarkIcon,
  },
  setup() {
    const settingsStore = useSettingsStore()
    const settings = ref<Setting[]>([])
    const deployed = ref(false)
    const isLoading = ref(true)
    const navigation = [
      { name: "Dashboard", href: "#", current: true },
      { name: "Team", href: "#", current: false },
      { name: "Projects", href: "#", current: false },
      { name: "Calendar", href: "#", current: false },
    ]

    onMounted(async () => {
      await settingsStore.fetchItems()
      settings.value = settingsStore.getItems()
      const deployedObj = settings.value.find((obj) => obj.name === "deployed")
      if (deployedObj) deployed.value = deployedObj.value === "true"
      else deployed.value = true
      isLoading.value = false
    })

    return {
      navigation,
      authStore: useAuthStore(),
      logo,
      settings,
      deployed,
      isLoading,
    }
  },
}
</script>
<style>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: radial-gradient(circle at 0% 50%, #ff8282 0%, rgba(0, 0, 0, 0) 70%), radial-gradient(circle at 50% 50%, #abffab 0%, rgba(0, 0, 0, 0) 70%),
    radial-gradient(circle at 100% 50%, #2a9696 0%, rgba(0, 0, 0, 0) 70%);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}
</style>
