import { defineStore } from "pinia"
import { Attachment } from "@/types/hrn"
import { destroyItem, loadItems, updateItem, uploadAttachment } from "@/stores/APIService.ts"

export const useAttachmentsStore = defineStore({
  id: "attachments",
  state: () => ({
    items: [] as Attachment[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      await loadItems(this, "attachments")
    },
    async addNewItem(file: File) {
      console.log("addNewItem with file", file)
      await uploadAttachment(file, this, "attachments")
    },
    async removeItem(item: Attachment) {
      await destroyItem(this, "attachments", item)
    },
    async changeItem(item: Attachment) {
      await updateItem(this, "attachments", item)
    },
    getItems(): Attachment[] {
      return this.items
    },
    getItemById(id: string): Attachment | undefined {
      return this.items.find((item) => item.attachment === id)
    },
  },
})
