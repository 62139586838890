import { createApp } from "vue"
import "./utils/string-extensions"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import Toast, { PluginOptions } from "vue-toastification"
import "vue-toastification/dist/index.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faBold, faItalic, faStrikethrough, faCode, faParagraph, faHeading, faListUl, faListOl, faRedo, faUndo, faEraser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import "swiper/css"

library.add(faBold, faItalic, faHeading, faParagraph, faStrikethrough, faCode, faListUl, faListOl, faRedo, faUndo, faEraser)

const pinia = createPinia()
const app = createApp(App)

app.use(router)
app.use(pinia)
app.component("font-awesome-icon", FontAwesomeIcon)

const options: PluginOptions = {}
app.use(Toast, options)

app.mount("#app")
