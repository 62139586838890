import { defineStore } from "pinia"
import { BlogPost } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"

export const useBlogPostsStore = defineStore({
  id: "blog-posts",
  state: () => ({
    items: [] as BlogPost[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "blogPosts")
    },
    async addNewItem(item: BlogPost) {
      await createItem(this, "blogPosts", item)
    },
    async removeItem(item: BlogPost) {
      await destroyItem(this, "blogPosts", item)
    },
    async changeItem(item: BlogPost) {
      await updateItem(this, "blogPosts", item)
    },
    getItems(): BlogPost[] {
      return this.items
    },
    getItemById(id: string): BlogPost | undefined {
      return this.items.find((item) => item.blogPost === id)
    },
    getItemByUrl(url: string): BlogPost | undefined {
      return this.items.find((item) => item.url === url)
    },
  },
})
