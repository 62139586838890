<template>
  <LayoutWrapper>
    <router-view />
  </LayoutWrapper>
</template>

<script lang="ts">
import "./style.css"
import { useSettingsStore } from "@/stores/SettingsStore.ts"
import { onMounted, ref } from "vue"
import { Setting } from "@/types/hrn"
import LayoutWrapper from "@/layouts/LayoutWrapper.vue"

export default {
  components: {
    LayoutWrapper,
  },
  setup() {
    const settings = ref<Setting[]>([])
    const settingsStore = useSettingsStore()

    const deployed = ref(false)
    const isLoading = ref(true)

    onMounted(async () => {
      await settingsStore.fetchItems()
      settings.value = settingsStore.getItems()
      const deployedObj = settings.value.find((obj) => obj.name === "deployed")
      if (deployedObj) deployed.value = deployedObj.value === "true"
      else deployed.value = true
      isLoading.value = false
    })

    return {
      settings,
      deployed,
      isLoading,
    }
  },
}
</script>
<style>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: radial-gradient(circle at 0% 50%, #ff8282 0%, rgba(0, 0, 0, 0) 70%), radial-gradient(circle at 50% 50%, #abffab 0%, rgba(0, 0, 0, 0) 70%),
    radial-gradient(circle at 100% 50%, #2a9696 0%, rgba(0, 0, 0, 0) 70%);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}
</style>
