<template>
  <div class="flex gap-3">
    <SunIcon class="h-5 w-5 text-gray-400 transition-all" v-if="!darkMode" />
    <MoonIcon class="h-5 w-5 text-gray-400 transition-all" v-else />
    <label class="relative mb-5 inline-flex cursor-pointer items-center">
      <input type="checkbox" class="peer sr-only" v-model="darkMode" @change="handleChange" />
      <div
        class="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
      ></div>
    </label>
  </div>
</template>

<script lang="ts">
import { SunIcon, MoonIcon } from "@heroicons/vue/20/solid/index.js"
import { useUserInputStore } from "@/stores/UserInputStore.ts" // Update the path
import { computed, onMounted, watchEffect } from "vue"

export default {
  name: "LightDarkToggle",
  components: {
    SunIcon,
    MoonIcon,
  },
  setup() {
    const userInputStore = useUserInputStore()
    const darkMode = computed(() => userInputStore.darkMode)

    // Set initial value based on user preference or OS preference
    onMounted(() => {
      if (userInputStore.darkMode === null) {
        userInputStore.setDarkMode(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
      }
    })

    const handleChange = () => {
      userInputStore.toggleDarkMode()
    }

    watchEffect(() => {
      if (darkMode.value) {
        document.body.classList.add("dark")
      } else {
        document.body.classList.remove("dark")
      }
    })

    return {
      darkMode,
      toggleDarkMode: userInputStore.toggleDarkMode,
      handleChange,
    }
  },
}
</script>
<style>
/* Add this inside your tailwind.css or styles.css */
.bg-inner-shadow {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.toggle-knob:before {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  border-radius: inherit;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.25), transparent);
}
</style>
