import { defineStore } from "pinia"
import { useLocalStorage } from "@vueuse/core"
import { Service, Category, Qualifier } from "@/types/hrn"

export const useUserInputStore = defineStore({
  id: "userInput",
  state: () => ({
    selectedCategories: useLocalStorage("userInputCategories", [] as Category[]),
    selectedServices: useLocalStorage("userInputServices", [] as Service[]),
    selectedQualifiers: useLocalStorage("userInputQualifiers", [] as Qualifier[]),
    address: useLocalStorage("userInputAddress", ""),
    latLng: useLocalStorage("userInputLatLng", { lat: 0, lng: 0 }),
    darkModeStorage: useLocalStorage("userDarkMode", null as "true" | "false" | null),
  }),
  getters: {
    darkMode(): boolean | null {
      return this.darkModeStorage === null ? null : this.darkModeStorage === "true"
    },
  },
  actions: {
    addAddress(address: string) {
      this.address = address
    },
    addLatLng(latLng: { lat: number; lng: number }) {
      this.latLng = latLng
    },
    addToCategorySelections(category: Category) {
      console.log("Service Selection Store addToCategorySelections", category)
      if (this.selectedCategories.find((c) => c.category === category.category)) {
        console.log("Category already exists")
        return
      }
      this.selectedCategories.push(category)
    },
    addToServiceSelections(service: Service) {
      if (this.selectedServices.find((s) => s.service === service.service)) {
        return
      }
      this.selectedServices.push(service)
    },
    addToQualifierSelections(qualifier: Qualifier) {
      if (this.selectedQualifiers.find((q) => q.qualifier === qualifier.qualifier)) {
        return
      }
      this.selectedQualifiers.push(qualifier)
    },
    removeFromCategorySelections(category: Category) {
      this.selectedCategories = this.selectedCategories.filter((c) => c.category !== category.category)
    },
    removeFromServiceSelections(service: Service) {
      this.selectedServices = this.selectedServices.filter((s) => s.service !== service.service)
    },
    removeFromQualifierSelections(qualifier: Qualifier) {
      this.selectedQualifiers = this.selectedQualifiers.filter((q) => q.qualifier !== qualifier.qualifier)
    },
    toggleServiceSelections(service: Service) {
      if (this.selectedServices.find((s) => s.service === service.service)) {
        this.removeFromServiceSelections(service)
      } else {
        this.addToServiceSelections(service)
      }
    },
    toggleCategorySelections(category: Category) {
      if (this.selectedCategories.find((c) => c.category === category.category)) {
        this.selectedServices.filter((s) => s.categoryId === category.category).forEach((s) => this.removeFromServiceSelections(s))
        this.removeFromCategorySelections(category)
      } else {
        this.addToCategorySelections(category)
      }
    },
    toggleQualifierSelections(qualifier: Qualifier) {
      if (this.selectedQualifiers.find((q) => q.qualifier === qualifier.qualifier)) {
        this.removeFromQualifierSelections(qualifier)
      } else {
        this.addToQualifierSelections(qualifier)
      }
    },
    getServiceSelections() {
      return this.selectedServices
    },
    getCategorySelections() {
      return this.selectedCategories
    },
    getQualifierSelections() {
      return this.selectedQualifiers
    },
    getAddress() {
      return this.address
    },
    getLatLng() {
      return this.latLng
    },
    setDarkMode(mode: boolean) {
      this.darkModeStorage = mode ? "true" : "false"
    },
    toggleDarkMode() {
      this.setDarkMode(this.darkModeStorage !== "true")
    },
    reset(): void {
      this.selectedCategories = []
      this.selectedServices = []
      this.selectedQualifiers = []
      this.address = ""
      this.latLng = { lat: 0, lng: 0 }
    },
  },
})
