import { defineStore } from "pinia"
import { ServiceOrganization } from "@/types/hrn"
import { useAuthStore } from "@/stores/AuthStore.ts"
import { authenticatedFetch } from "@/stores/APIService.ts"
import { Profile } from "@/types/hrn"

export const useProfileStore = defineStore({
  id: "profile",
  state: () => ({
    username: localStorage.getItem("username") || "",
    loaded: localStorage.getItem("loaded") === "true",
    role: localStorage.getItem("role") || "",
    serviceOrganizations: JSON.parse(localStorage.getItem("serviceOrganizations") || "[]") as ServiceOrganization[],
  }),
  actions: {
    async loadProfileData(reload = false) {
      console.log("loadProfileData", this.loaded, reload)
      if (this.loaded && !reload) {
        console.log("loadProfileData: already loaded")
        return
      }

      const authStore = useAuthStore()
      this.username = authStore.getUsername()

      const response = await authenticatedFetch(`${import.meta.env.VITE_APP_API_URL}/users/${this.username}`, {
        method: "GET",
      })

      if (response.ok) {
        const data = await response.json()
        this.setProfile(data.username, data.role, data.serviceOrganizations)
        this.loaded = true
      }
    },
    setProfile(username: string, role: string, serviceOrganizations: ServiceOrganization[]) {
      this.username = username
      this.role = role
      this.serviceOrganizations = serviceOrganizations

      localStorage.setItem("username", username)
      localStorage.setItem("loaded", "true")
      localStorage.setItem("role", role)
      localStorage.setItem("serviceOrganizations", JSON.stringify(serviceOrganizations))
    },
    getUsername(): string {
      return this.username
    },
    async getProfile(): Promise<Profile> {
      if (!this.loaded) {
        await this.loadProfileData()
      }
      return Promise.resolve({ Username: this.username, role: this.role, serviceOrganizations: this.serviceOrganizations })
    },
  },
})
