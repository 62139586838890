<template>
  <button type="button" class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    <span class="sr-only">View notifications</span>
    <BellIcon class="h-6 w-6" aria-hidden="true" />
  </button>
</template>
<script lang="ts">
import { BellIcon } from "@heroicons/vue/24/outline"
export default {
  name: "NotificationsMenu",
  components: {
    BellIcon,
  },
  setup() {
    return {
      BellIcon,
    }
  },
}
</script>
