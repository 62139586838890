import { defineStore } from "pinia"
import { User } from "@/types/hrn"
import { createItem, destroyItem, updateItem, getEndpoint, handleError } from "@/stores/APIService.ts"
import { authenticatedFetch } from "@/stores/APIService.ts"

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    items: [] as User[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      console.log("User: fetchItems")
      console.log("API Service: loadItems", "Users")
      this.status = "loading"
      const endpoint = getEndpoint("users")
      try {
        const response = await authenticatedFetch(endpoint)
        const results: { data: [] } = await response.json()
        this.items = results.data
        this.status = "loaded"
      } catch (error) {
        handleError(this, error)
      }
    },
    async addNewItem(item: User) {
      await createItem(this, "users", item)
    },
    async removeItem(item: User) {
      await destroyItem(this, "users", item, "Username")
      await this.fetchItems()
    },
    async changeItem(updatedItem: User) {
      await updateItem(this, "users", updatedItem, "Username")
    },
    getItems(): User[] {
      return this.items
    },
    getItemById(Username: string): User | undefined {
      console.log("getItemById", Username)
      return this.items.find((item) => item.Username === Username)
    },
    async inviteUser(email: string) {
      console.log("inviteUser", email)

      this.status = "loading"
      try {
        const response = await authenticatedFetch(`${import.meta.env.VITE_APP_API_URL}/users/invite`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        })
        if (response.ok) {
          await this.fetchItems()
          this.status = "loaded"
        } else {
          const error = await response.text()
          throw new Error(error || "Failed to create item.")
        }
      } catch (error) {
        if (error instanceof Error) {
          this.error = error.message
        } else {
          this.error = "An unknown error occurred."
        }
        this.status = "error"
      }
    },
  },
})
