<template>
  <div class="flex">
    <div class="mr-10 flex flex-shrink-0 items-center">
      <router-link to="/">
        <img class="block h-[4em] w-auto" :src="logo" alt="Home Repair Network" />
      </router-link>
    </div>
    <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
      <router-link
        v-for="item in navigation"
        :key="item.name"
        :to="item.href"
        :class="[
          item.current ? 'border-indigo-500 text-gray-100' : 'border-transparent text-white hover:border-gray-300 hover:text-gray-200',
          'inline-flex items-center border-b-2 px-1 pt-1 font-medium uppercase',
        ]"
        :aria-current="item.current ? 'page' : undefined"
        >{{ item.name }}
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import logo from "@/assets/logo.svg"

export default {
  name: "MainMenu",
  data() {
    return {
      navigation: [
        { name: "About", href: "/about", current: true },
        { name: "Team", href: "/team", current: false },
        { name: "Projects", href: "/projects", current: false },
        { name: "Get Involved!", href: "/get-involved", current: false },
      ],
    }
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
